import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import Footer from './Footer/Footer';
import { useNavigate } from 'react-router-dom';
import './Carrers.css'; // Ensure this path is correct

const Careers = () => {
  const [currentCategory, setCurrentCategory] = useState('all');
  const [showModal, setShowModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const navigate = useNavigate();

  // Updated job data with new jobs added
  const jobs = [
    {
      id: 'HAR_INF_501',
      title: 'Software Developer',
      company: 'Infosys',
      location: 'Bengaluru',
      description: 'Develop and maintain software applications for VISA clients.',
      skills: ['Java 8', 'SpringBoot', 'Selenium'],
      experience: '5+ years',
      education: 'Bachelor of Science in Computer Science',
      category: 'infosys',
    },
    {
      id: 'HAR_INF_502',
      title: 'Java Developer',
      company: 'Infosys',
      location: 'Bengaluru',
      description: 'Develop and maintain Java-based applications. Work with automation tools and big data technologies.',
      skills: ['Java', 'Kafka', 'BBD'],
      experience: '5+ years',
      education: 'Bachelor of Science in Computer Science',
      category: 'infosys',
      additionalInfo: 'Immediate Joiners Required',
    },
    {
      id: 'HAR_WAF_503',
      title: 'Java Backend Developer',
      company: 'Wayfair',
      location: 'Remote',
      description: 'Experience as a backend, full lifecycle software engineer with deep knowledge of modern programming languages (e.g., Java), GCP or other cloud services, and Kubernetes. Proficient in system design at scale, relational database schema design, troubleshooting, and effective communication with engineers.',
      skills: ['Java', 'GCP', 'Kubernetes'],
      experience: '5+ years',
      education: 'Bachelor of Science in Computer Science',
      category: 'wayfair',
      additionalInfo: 'Immediate Joiners Required',
    },
    {
      id: 'HAR_WAF_504',
      title: 'Senior Software Engineer',
      company: 'Wayfair',
      location: 'Remote',
      description: 'Expertise in scalable cloud solutions, front-end development, microservice architectures, and handling high-volume messaging. Familiarity with GCP, React, Angular, and modern tools.',
      skills: [
        'GCP', 'Front-end Development', 'Microservice Architectures', 'Async Messaging',
        'Relational and NoSQL Data Stores', 'Kafka', 'Kubernetes', 'Containerization',
        'Java Microservices', 'GraphQL', 'NextJS', 'React'
      ],
      experience: '10+ years',
      education: 'Bachelor of Science in Computer Science',
      category: 'wayfair',
    },
    {
      id: 'HAR_WAF_505',
      title: 'Engineering Manager',
      company: 'Wayfair',
      location: 'Remote',
      description: `Leadership and Team Management: 5+ years in engineering, including at least 4 years leading high-performance teams. Expertise in making architectural and design decisions for large-scale platforms while balancing time-to-market and flexibility.
      Growth and Performance: Proven track record in managing and enabling team growth and performance, and hiring and developing engineering teams.
      Architectural Experience: Hands-on experience designing and building high-scale products with outstanding user experience. Skilled in managing and scaling microservices in cloud-native architectures.
      Collaboration and Communication: Strong capability to communicate and collaborate across organizations, influencing decisions with and without direct authority. Effective in fast-paced, agile environments.
      Analytical Skills: Integrates technical and business requirements with a focus on customer-facing experiences and capabilities.`,
      skills: [
        'Leadership', 'Team Management', 'Architectural Design', 'Microservices',
        'Cloud Platforms', 'Scalability'
      ],
      experience: '10+ years',
      education: 'Bachelor of Science in Computer Science',
      category: 'wayfair',
    },
    {
      id: 'HAR_EXL_506',
      title: 'Senior Java Backend Developer',
      company: 'EXL',
      location: 'Remote Pune/Hyderabad',
      description: `Solve complex software engineering problems, learn new skills, and build expertise in areas of your interest. Design, code, test, debug, optimize, and document complex web/middleware applications using other technologies. Develop all layers of enterprise applications and get involved in interesting DevOps activities to have end-to-end involvement. Provide direction and support to juniors to help their understanding of more complex software engineering. Perform other duties as assigned or apparent. Participate in Scrum with activities such as assisting QAs, perform code reviews, unit testing, research spikes, support company’s architectural standards, contribute in creating new standards supporting continuous improvement, etc.`,
      skills: [
        'Java 8/J2EE', 'Spring', 'Spring Boot', 'Microservices', 'Hibernate', 'REST', 'Docker',
        'GCP'
      ],
      experience: '5+ years',
      education: 'Bachelor’s in Engineering - Computer Science / IT',
      category: 'exl',
    },
    {
      id: 'HAR_EXL_507',
      title: 'Full Stack Java Engineer',
      company: 'EXL',
      location: 'Pune, Hyderabad, Hybrid',
      description: `Design, develop, test, and deploy high-quality, scalable web applications using Java and related technologies. Develop and maintain front-end interfaces using modern JavaScript frameworks. Implement RESTful APIs and integrate with various third-party services and databases. Collaborate with architects and other engineers to design system architecture and ensure seamless integration of front-end and back-end components. Write clean, maintainable code and follow best practices. Perform code reviews, implement automated testing, and ensure software quality. Design and optimize database schemas and queries. Work with relational and NoSQL databases. Communicate technical concepts effectively and participate in agile development processes. Debug and resolve technical issues and provide support for existing applications. Continuously monitor and improve application performance and user experience.`,
      experience: '5-7 years',
      education: 'Bachelor of Science in Computer Science',
      skills: [
        'Java', 'JavaScript', 'Angular', 'CI/CD', 'RESTful APIs',
        'MySQL', 'PostgreSQL', 'MongoDB', 'Docker', 'Kubernetes',
        'Google Cloud'
      ],
      additionalSkills: [
        'Knowledge of Java collection framework',
        'Expertise in Spring and Hibernate frameworks',
        'Experience with containerization and orchestration tools',
        'Understanding of cloud platforms',
        'Strong problem-solving skills',
        'Excellent communication and teamwork skills'
      ],
      category: 'exl',
    },
    {
      id: 'HAR_EXL_508',
      title: 'Scrum Master',
      company: 'EXL',
      location: 'Pune, Hyderabad, Gurgram, Hybrid',
      description: `Design, develop, test, and deploy high-quality, scalable web applications using Java and related technologies. Develop and maintain front-end interfaces using modern JavaScript frameworks. Implement RESTful APIs and integrate with various third-party services and databases. Collaborate on system architecture and ensure seamless integration of front-end and back-end components. Write clean, maintainable code and follow best practices. Perform code reviews, implement automated testing, and ensure software quality. Design and optimize database schemas and queries. Work with relational and NoSQL databases. Communicate technical concepts effectively and participate in agile development processes. Debug and resolve technical issues and provide support for existing applications. Continuously monitor and improve application performance and user experience.`,
      experience: '8-12 years',
      education: 'Bachelor of Science in Computer Science',
      skills: [
        'Java', 'JavaScript', 'Angular', 'CI/CD', 'RESTful APIs',
        'MySQL', 'PostgreSQL', 'MongoDB', 'Docker', 'Kubernetes',
        'Google Cloud'
      ],
      additionalSkills: [
        'Proven experience as a Scrum Master in software development environments',
        'Deep understanding of Agile methodologies and principles',
        'Strong facilitation, coaching, and mentoring skills',
        'Ability to manage multiple teams and projects effectively',
        'Excellent problem-solving and conflict resolution skills'
      ],
      category: 'exl',
    },
    {
      id: 'HAR_EXL_509',
      title: 'Data Analyst',
      company: 'EXL',
      location: 'Remote',
      description: `Act as a liaison between the technical and business teams. Connect with client SMEs to understand functionality and data. Perform data profiling to assess data quality and standardize reference and master data across systems. Work on data mapping, data lineage documentation, data models, and design documents to help stakeholders understand data mappings and transformations.`,
      skills: [
        'Snowflake', 'SQL', 'Data Mapping', 'Data Transformations', 'Data Profiling',
        'Business Requirement Gathering', 'Source-to-Target Mappings (STTM)'
      ],
      experience: '4-7 years',
      education: 'Bachelor of Science in Computer Science',
      category: 'exl',
      role: 'Data Analyst',
      numberOfOpenPositions: 2,
      mustHaveQualifications: {
        experience: '4 to 7 years of work experience in large-scale data applications focusing on Business Intelligence (BI) and Data Analytics.',
        skills: [
          'Proven experience with Snowflake, SQL, and data mapping.',
          'Strong data profiling and data transformation skills.',
          'Excellent verbal and written communication skills.'
        ],
        domainExpertise: 'Experience working with clients in the Insurance domain, specifically Property and Casualty Insurance (P&C).',
        personalAttributes: [
          'Self-starter with the ability to integrate quickly into the team.',
          'Ability to work independently towards team goals.'
        ]
      },
      additionalSkills: [
        'Exposure to data consolidation, transformation, and standardization from various systems.'
      ],
      niceToHave: {
        domainKnowledge: 'Familiarity with the Insurance domain.',
        technicalSkills: 'Additional knowledge in SQL.'
      },
      roleAndResponsibilities: {
        liaisonRole: [
          'Act as a liaison between the technical and business teams.',
          'Connect with client SMEs to understand functionality and data requirements.'
        ],
        dataProfiling: [
          'Perform data profiling to assess the quality of data and critical data elements.',
          'Standardize reference and master data across systems.'
        ],
        documentation: [
          'Develop data mapping, data lineage documentation, data models, and design documents.',
          'Ensure stakeholders understand data mappings and transformations.'
        ]
      }
    }
 
  ];

  const handleCategoryChange = (category) => {
    setCurrentCategory(category);
  };

  const openModal = (job) => {
    setSelectedJob(job);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedJob(null);
  };

  const handleApplyNow = () => {
    if (selectedJob) {
      navigate('/application', {
        state: { jobId: selectedJob.id, jobTitle: selectedJob.title }
      });
    }
  };

  const filteredJobs = currentCategory === 'all'
    ? jobs
    : jobs.filter(job => job.category === currentCategory);

  return (
    <div className="careers-page">
      <div className="container my-5">
        <h1 className="careers-title">Open Positions</h1>
        <div className="tabs mb-4 text-center">
          <button className="btn btn-link" onClick={() => handleCategoryChange('all')}>
            All Positions
          </button>
          <button className="btn btn-link" onClick={() => handleCategoryChange('infosys')}>
            Infosys
          </button>
          <button className="btn btn-link" onClick={() => handleCategoryChange('wayfair')}>
            Wayfair
          </button>
          <button className="btn btn-link" onClick={() => handleCategoryChange('exl')}>
            EXL
          </button>
          <button className="btn btn-link" onClick={() => handleCategoryChange('corpus')}>
            Corpus
          </button>
        </div>

        {filteredJobs.length === 0 ? (
          <p className="text-center text-black" style={{ color: 'black' }}>
            Currently, there are no jobs available in this category. Please check back later!
          </p>
        ) : (
          <div className="row">
            {filteredJobs.map((job) => (
              <div key={job.id} className="col-md-4 mb-4">
                <div className="card h-100 shadow-sm">
                  <div className="card-body d-flex flex-column">
                    <h5 className="card-title">{job.title}</h5>
                    <p className="card-text">
                      <strong>Job ID:</strong> {job.id}
                      <br />
                      <strong>Experience:</strong> {job.experience}
                      <br />
                      <strong>Skills:</strong> {job.skills.join(', ')}
                      <br />
                      <strong>Company:</strong> {job.company}
                    </p>
                    <Button variant="primary" onClick={() => openModal(job)}>
                      Show Full Details
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedJob?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>Job ID:</strong> {selectedJob?.id}</p>
          <p><strong>Company:</strong> {selectedJob?.company}</p>
          <p><strong>Location:</strong> {selectedJob?.location}</p>
          <p><strong>Description:</strong> {selectedJob?.description}</p>
          <p><strong>Experience:</strong> {selectedJob?.experience}</p>
          <p><strong>Education:</strong> {selectedJob?.education}</p>
          <p><strong>Skills:</strong> {selectedJob?.skills.join(', ')}</p>
          {selectedJob?.additionalSkills && (
            <div>
              <strong>Additional Skills:</strong>
              <ul>
                {selectedJob.additionalSkills.map((skill, i) => (
                  <li key={i}>{skill}</li>
                ))}
              </ul>
            </div>
          )}
          {selectedJob?.mustHaveQualifications && (
            <div>
              <strong>Must-Have Qualifications:</strong>
              <ul>
                {Object.entries(selectedJob.mustHaveQualifications).map(([key, value], i) => (
                  <li key={i}>
                    <strong>{key.replace(/([A-Z])/g, ' $1')}: </strong>
                    {Array.isArray(value) ? value.join(', ') : value}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {selectedJob?.niceToHave && (
            <div>
              <strong>Nice to Have:</strong>
              <ul>
                {Object.entries(selectedJob.niceToHave).map(([key, value], i) => (
                  <li key={i}>
                    <strong>{key.replace(/([A-Z])/g, ' $1')}: </strong>
                    {Array.isArray(value) ? value.join(', ') : value}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {selectedJob?.roleAndResponsibilities && (
            <div>
              <strong>Role and Responsibilities:</strong>
              <ul>
                {Object.entries(selectedJob.roleAndResponsibilities).map(([key, value], i) => (
                  <li key={i}>
                    <strong>{key.replace(/([A-Z])/g, ' $1')}: </strong>
                    <ul>
                      {value.map((item, j) => <li key={j}>{item}</li>)}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {selectedJob?.additionalInfo && (
            <p><strong>Additional Info:</strong> {selectedJob.additionalInfo}</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleApplyNow}>
            Apply Now
          </Button>
        </Modal.Footer>
      </Modal>

      <Footer />
    </div>
  );
};

export default Careers;
